<template>
  <div class="card">
    <p class="instructions" @click="instructions">活动说明</p>
    <img src="@/assets/img/card_logo.png" class="card_logo">
    <img src="@/assets/img/card_banner.png" class="card_banner">
    <div class="content">
      <p class="h2">限时体验卡</p>
      <p class="h3">膝关节疼痛 一对一测评</p>
      <div class="tabs">
        <p>精准测评</p>
        <p>个性处方</p>
        <p>科学指导</p>
      </div>
      <div class="qrTitle">详情咨询企业微信</div>
      <img src="@/assets/img/qrCode.png" class="qrCode">
      <div class="line"></div>
      <div class="cardholder">
        <p class="userName">{{timeData.name}}</p>
        <div class="time gray" v-if="timeData.status == 0">
          <p>受理中，请稍候...</p>
          <p>工作人员将会与您沟通具体时间</p>
        </div>
        <div class="time" v-else-if="timeData.status == 1">
          <p>到店时间</p>
          <p>{{timeData.assessDate}} {{timeData.assessTime}}</p>
        </div>
        <div class="time green" v-else-if="timeData.status == 3">
          <p>使用完成</p>
          <p> 小诺肌骨祝您身体健康！ </p>
        </div>
      </div>
    </div>
    <div class="line"></div>

    <p class="text"><span>联系电话</span><br><a href="tel:18657980270 " class="tel blue">18657980270</a></p>
    <p class="text"><span>注意事项</span><br>请您穿宽松衣物，方便康复师为您测评<br>请携带医院检查结果(如有)，如X线片、核磁报告<br></p>
    <p class="text"><span>详细地址</span><br>北京市西城区西直门南大街<br>成铭大厦C座23层 闻涛运动康复诊所</p>
  </div>
</template>
<script>
import { scheduleDetail } from "@/api/getData"
export default {
  name: "Card",
  data() {
    return {
      timeData: {}
    }
  },
  methods: {
    instructions() {
      this.$dialog.alert({
        title: '活动说明',
        message: '<p>膝关节健康测评体验请提前预约。</p><p>请您按号就诊，否则无法现场接待，敬请谅解。</p><p>本卡不找零不兑现。</p><p>最终解释权归小诺肌骨所有。</p><p class="hint">点击任意处关闭</p>',
        showConfirmButton: false,
        closeOnClickOverlay: true
      });
    },

    getInformation() {
      scheduleDetail(this.$route.query.scheduleId).then(res => {
        this.timeData = res.data
      })
    }
  },
  mounted() {
    this.getInformation()
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: #f6fbfe;
  overflow: hidden;
  .instructions {
    position: absolute;
    left: 0.16rem;
    top: 0.16rem;
    width: 0.95rem;
    height: 0.32rem;
    border-radius: 0.19rem;
    opacity: 0.8;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #8fd2df;
    backdrop-filter: blur(10px);
    line-height: 0.32rem;
    text-align: center;
    color: #24bbd3;
    font-size: 0.14rem;
  }
  .card_logo {
    position: absolute;
    right: 0.08rem;
    top: 0.06rem;
    width: 1.1rem;
  }
  .card_banner {
    display: block;
    width: 100%;
  }
  .content {
    padding: 0 0.16rem;
    .h2 {
      font-family: PangMenZhengDao;
      font-size: 0.2rem;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 0.24rem;
      line-height: 1;
    }
    .h3 {
      line-height: 0.4rem;
      font-family: PangMenZhengDao;
      font-size: 0.345rem;
      color: #24bbd3;
      margin-top: 0.09rem;
    }
    .tabs {
      display: flex;
      justify-content: space-between;
      margin: 0.16rem 0 0.3rem;
      p {
        width: 1.1rem;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        font-size: 0.18rem;
        color: rgb(36, 187, 211);
        border-radius: 0.06rem;
        background: rgba(186, 224, 234, 0.3);
        font-weight: bold;
      }
    }
    .qrTitle{
      font-family: PangMenZhengDao;
font-size: 0.2rem;
font-weight: normal;
letter-spacing: 0em;
color: rgba(0, 0, 0, 0.6);
text-align: center;
margin-bottom: 0.22rem;
    }
    .qrCode{
      display: block;

      width: 1.71rem;
      margin: 0 auto;
      height: 1.71rem;
    }
    .line{
      margin: 0.32rem 0;
      height: 2px;
opacity: 1;

background: rgba(164, 218, 229, 0.2);
    }
    .cardholder {
      height: 2.03rem;
      box-sizing: border-box;
      opacity: 0.8;
      color: #24bbd3;
      background: #ffffff;
      border-radius: 0.08rem;
      backdrop-filter: blur(0.1rem);
      border: 0.02rem solid #8fd2df;
      overflow: hidden;
      margin-bottom: 0.2rem;

      .userName {
        height: 0.93rem;
        font: 700 0.46rem not specified;
        line-height: 0.93rem;
        text-align: center;
        border-bottom: 0.02rem solid #a4dae5;
      }

      .time {
        height: 0.72rem;
        padding: 0.18rem 0;
        font-size: 0.18rem;
        font-weight: 700;
        text-align: center;

        p {
          height: 0.36rem;
          line-height: 0.36rem;
        }
      }
      .gray {
        color: #a8a8a8;
      }
      .green {
        color: #00b578;
      }
    }
    h4 {
      font-size: 0.18rem;
      line-height: 0.36rem;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 0.2rem;
    }
  }
  .line {
    height: 0.02rem;
    background: rgba(164, 218, 229, 0.2);
    margin: 0.3rem 0 0.2rem;
  }
  a.tel {
    display: block;
    font-size: 0.18rem;
    text-align: left;
    line-height: 0.36rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;

    span {
      font-weight: 700;
    }
  }
  p.text {
    font-size: 0.18rem;
    line-height: 0.36rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0.2rem;
    padding: 0 0.16rem;

    span {
      font-weight: 700;
    }

    a {
      color: #24bbd3;
    }
  }
}
</style>